

export default function InputTotal(props) {
    return (
      <div className="inputTotal">
        TOTAL
        <span className="inputTotal__grey">( sans remises )</span>
        <span className="inputTotal__amount">
          {props.total ? 
          `${props.total} €`
        : `0 €`}  
          </span>
      </div>
    );
  }