import React from "react";
import { usersDatas } from "../../datas/usersDatas";
import NumericKeypad from "../utils/NumericKeypad";
import {motion} from 'framer-motion'



export default function ExpenseDetail(props) {
    const styleImageForWho = (idUser) => idUser === parseInt(props.expenseDetail.forWho) ? "-2px 2px 10px 6px rgba(0,170,255,0.47)" : "none"
    
    const handleClose = () => {
        props.setExpenseDetail({})
    }

    var handleUpdate = () => {
        props.setNewExpense(props.expenseDetail)
        props.setUpdateDetail(true)
    }

    var handleRemove = () => {
        var arrayOfExpense = props.historical.filter((expense, position) => position !== props.expensePosition)
        props.setHistorical(arrayOfExpense)
        props.setExpenseDetail({})
        props.setExpensePosition(0)
    }
    return (
        <motion.div 
            exit = {{y : 1000}}
            animate = {{y : 0}}
            initial = {{y : 1000}} 
            transition={{ type : 'linear' }}
            className = "expenseDetail"
        >
            <div className = "expenseDetail__container">
                    <div>
                        <div className = "expenseDetail__container__element">
                            <p>Qui ? </p>
                            <div>
                                {props.expenseDetail.who.map(userExpense => (
                                    <div style = {{boxShadow : '-2px 2px 10px 6px rgba(0,170,255,0.47)'}}>
                                    <img key = {usersDatas.find(user => user.id === userExpense).name} src = {usersDatas.find(user => user.id === userExpense).image} alt = {usersDatas.find(user => user.id === userExpense).name}/>
                                    <p><span>{
                                        usersDatas.find(user => user.id === userExpense).surname ? 
                                            usersDatas.find(user => user.id === userExpense).surname
                                        :
                                            usersDatas.find(user => user.id === userExpense).name}</span>
                                    </p>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    <div>
                        {props.expenseDetail.who.length !== usersDatas.length ? 
                        <div className = "expenseDetail__container__element">
                            <p>Combien ? </p>
                            <div>{props.expenseDetail.howMuch}€</div>
                        </div>
                        :

                        null }
                        <div className = "expenseDetail__container__element">
                            <p>Remise ? </p>
                            <div>{props.expenseDetail.discount}€</div>
                        </div>
                    </div>


            {props.expenseDetail.who.length === 2 ? 
                <div>

                    <div className = "expenseDetail__container__element">
                        <p>Pour qui ?</p>
                        <div>
                            {props.expenseDetail.who.map(userExpense => (
                                <div style = {{boxShadow : styleImageForWho(userExpense)}}>
                                <img key = {usersDatas.find(user => user.id === userExpense).name} src = {usersDatas.find(user => user.id === userExpense).image} alt = {usersDatas.find(user => user.id === userExpense).name}/>
                                <p><span>{
                                    usersDatas.find(user => user.id === userExpense).surname ? 
                                        usersDatas.find(user => user.id === userExpense).surname
                                    :
                                        usersDatas.find(user => user.id === userExpense).name}</span>
                                </p>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className = "expenseDetail__container__element">
                        <p>Ratio ?</p>
                        <div id = "number"><span>1/</span><span>{props.expenseDetail.ratio}</span></div>
                    </div>
                </div>

                : null
            }
            </div>
            <div className = "expenseDetail__buttons">
                <button onClick = {handleRemove}>Supprimer</button>
                <button onClick = {handleUpdate}>Modifier</button>
                <button onClick = {handleClose}>Fermer</button>
            </div>
        </motion.div>

    )
}