
import total1 from "../../img/total-1.png";
import total2 from "../../img/total-2.png";
import remises from "../../img/remises.png";

export default function Totals (props) {
    /*Props available : 
      - props.total : Accessor of state total
      - props.historical : Accessor of historical
      - props.handleClickTotalBox : Change state of totalBox
    */
    
    //Sum of all reductions
      var discounts = () => { 
          var sum = 0
          for (var expense of props.historical) {
              sum += parseFloat(expense.discount)
          }
          return sum 
      }

    
    return(
        <div className="dashboard__totals">
        <div className="dashboard__totals__total" onClick = {props.handleClickTotalBox}>
          <img src={total1} alt="total" />
          <span className="dashboard__totals__user__total">
            <span>

              {
                props.total ? 
                  `${parseFloat(props.total).toFixed(2)} €`
                : 
                `0 €`
              }

            </span>
          </span>
        </div>
        <div className="dashboard__totals__total">
          <img src={total2} alt="total avec remises" />
          <span className="dashboard__totals__user__total">
            <span>
              {`${(parseFloat(props.total - discounts())).toFixed(2)} €`}
            </span>
            
          </span>
        </div>
        <div className="dashboard__totals__total">
          <img src={remises} alt="remises" />
          <span className="dashboard__totals__user__total">
            <span>
              {`${parseFloat(discounts()).toFixed(2)} €`}
            </span>
          </span>
        </div>
      </div>
    )
}