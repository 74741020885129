import button1 from "../img/touch1.jpg";
import button2 from "../img/touch2.jpg";
import button3 from "../img/touch3.jpg";
import button4 from "../img/touch4.jpg";
import button5 from "../img/touch5.jpg";
import button6 from "../img/touch6.jpg";
import button7 from "../img/touch7.jpg";
import button8 from "../img/touch8.jpg";
import button9 from "../img/touch9.jpg";
import buttonBack from "../img/touchback.jpg";
import buttonMultiply from "../img/touchmultiply.jpg";
import buttonReturn from "../img/touchreturn.jpg";
import button0 from "../img/touch0.jpg";
import buttonComma from "../img/touchcomma.jpg";

export const numbersDatas = [
  {
    name: "Touche 1",
    image: button1
  },
  {
    name: "Touche 2",
    image: button2
  },
  {
    name: "Touche 3",
    image: button3
  },
  {
    name: "Touche 4",
    image: button4
  },
  {
    name: "Touche 5",
    image: button5
  },
  {
    name: "Touche 6",
    image: button6
  },
  {
    name: "Touche 7",
    image: button7
  },
  {
    name: "Touche 8",
    image: button8
  },
  {
    name: "Touche 9",
    image: button9
  }
];

export const toolsRight = [
  {
    name: "back",
    image: buttonBack
  },
  {
    name: "multiply",
    image: buttonMultiply
  },
  {
    name: "return",
    image: buttonReturn
  }
];

export const toolsBottom = [
  {
    name: "Touche 0",
    image: button0
  },
  {
    name: "comma",
    image: buttonComma
  }
];
