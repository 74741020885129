import { usersDatas } from "../datas/usersDatas"

export var calculateExpense = (idUser, expense) => {
    if(expense.who.length === usersDatas.length) {
        return -(expense.discount / usersDatas.length)
    } else {
        if (expense.ratio) {
            if (idUser === expense.forWho){
                var value = (expense.howMuch - expense.discount) / expense.ratio

            } else {
                var value = (expense.howMuch - expense.discount) - ((expense.howMuch - expense.discount) / expense.ratio)
            }
        } else {
            if(expense.who.length === 1 && idUser === expense.who[0]) {
                var value = (expense.howMuch - expense.discount)
            } else {
                var value = (expense.howMuch - expense.discount) / (usersDatas.length - 1)
            }

        }
        return value
    }
    }





export var userTotal = (idUser, total, historical) => {
    var newTotal = total
    for(var expense of historical) {
        if(expense.who.length < 3) {
            newTotal -= expense.howMuch
        }
    }
    var totalPerPerson = newTotal / usersDatas.length
    for(var expense of historical) {
        if (expense.who.includes(idUser)){
            if(expense.who.length < usersDatas.length) {
                /*if(expense.forWho === parseInt(idUser)) {
                    var arrayOfWho = expense.who.filter(user => user !== idUser)
                    var valueOfTheOther = calculateExpense(arrayOfWho[0], expense) 
                    totalPerPerson -= valueOfTheOther
                }*/
                var value = calculateExpense(idUser, expense)
                totalPerPerson += value

            } else {
                var value = calculateExpense(idUser, expense)
                totalPerPerson += value
            }
        } 
    }
    return totalPerPerson
}
