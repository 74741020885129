import btswipe from "../../img/btswipe.png";
import {motion, useMotionValue} from "framer-motion"

export default function ToggleWindow(props) {
  const y = useMotionValue(0)
  const onPanToggle = (e, info) => {
      if(info.point.y > 500) {
        props.handleClickWindow()
      }

  }
  return (
    
    <motion.div 
      exit = {{y : 1000}}
      animate = {{
        y : 0
      }}
      initial = {{y : 1000}} 
      transition={{ type : 'linear'}}
      dragTransition={{ bounceStiffness: 600, bounceDamping: 20 }}
      dragElastic={0.5}
      onPanEnd = {onPanToggle}
      drag="y"
      dragConstraints={{ bottom: 0, top: 0 }}
      style = {{y : y}}
      className="toggleWindow">
        <motion.div className="toggleWindow__swipe" onClick = {props.handleClickWindow}
            y = {y}
        >
          <img src={btswipe} alt="fermeture" />
        </motion.div>
      {props.children}
    </motion.div>
  );
}
