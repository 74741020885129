
import './styles/style.css';
import './styles/normalize.css';
import Main from './components/Main';

function App() {
  return <Main/>;
}



export default App;


