import React, {useState} from 'react'
import { usersDatas } from '../../datas/usersDatas'
import NumericKeypad from '../utils/NumericKeypad'
import validate from '../../img/validate.png'
import cancel from "../../img/CloseCross.png";
import { motion } from 'framer-motion';


export default function AddExpense(props) {
    /*Props available : 
        - addExpenseBox : Accessor of state addExpenseBox
        - setAddExpenseBox : Mutator of state addExpenseBox
        - setHistorical : Mutator of state historical
        - historical : Accessor of state historical
    */

    //States for the components
    const [slideActive, setSlideActive] = useState(0)
    const [positionValue, setPositionValue] = useState(0)

    //Navigation slide functions
        //1. Previous
        const handleClickPrevious = () => {
            if(slideActive !== 0) {
                setSlideActive(slideActive - 1)
                setPositionValue(positionValue + 322)
            }
        }
        //2. Next
        const handleClickNext = () => {
            if(props.newExpense.who.length === 1) {
                if(slideActive < 3 && props.newExpense.who.length > 0) {
                setSlideActive(slideActive + 1)
                setPositionValue(positionValue + (-322))
                } 
            } else if (props.newExpense.who.length === 2) {
                if(slideActive < 5 && props.newExpense.who.length > 0) {
                    setSlideActive(slideActive + 1)
                    setPositionValue(positionValue + (-322))
                } 
            } else if (props.newExpense.who.length === usersDatas.length ){

                if(slideActive < 2 && props.newExpense.who.length > 0) {
                    setSlideActive(slideActive + 1)
                    setPositionValue(positionValue + (-322))
                } 
            } else if (props.newExpense.who.length > 2 || props.newExpense.who.length === usersDatas.length) {
                if(slideActive < 3 && props.newExpense.who.length > 0) { 
                    setSlideActive(slideActive + 1)
                    setPositionValue(positionValue + (-322))
                }

            }

        }

    //Change function for values in state props.newExpense
        //1. ForKeyPad
        const handleChangeInput = (value) => {
            if(props.newExpense.who.length === usersDatas.length){
                props.setNewExpense((state) => {
                    return {...state, "discount" : value}
                })
            } else {
                if(slideActive === 1) {
                    props.setNewExpense((state) => {
                        return {...state, "howMuch" : value}
                    })
                } else if(slideActive === 2) {
                    props.setNewExpense((state) => {
                        return {...state, "discount" : value}
                    })
                } else if(slideActive === 3) {
                    props.setNewExpense((state) => {
                        return {...state, "ratio" : value}
                    })
                } 
            }
            
        }

        //2. For others value in state props.newExpense
        const handleClickWho = (idUser) => {
            props.setNewExpense({
                who : [], 
                howMuch : "",
                discount : "", 
                ratio : "", 
                forWho : 0
        
            })

            if(props.newExpense.who.length < usersDatas.length && !props.newExpense.who.includes(idUser)) {
                const arrayOfWho = props.newExpense.who
                arrayOfWho.push(idUser)
                props.setNewExpense(state => {
                    return {...state, "who" : arrayOfWho }
                })
                
            } else {
                const arrayOfWho = props.newExpense.who.filter(user => user !== idUser)
                props.setNewExpense(state => {
                    return {...state, "who" : arrayOfWho }
                })
            }

        }

        const handleClickForWho = (idUser) => {
            if(idUser !== parseInt(props.newExpense.forWho)) {
                props.setNewExpense(state => {
                    return {...state, "forWho" : idUser }
                })
            } else {
                props.setNewExpense(state => {
                    return {...state, "forWho" : 0 }
                })
            }
        }

    //Style when a user is in props.newExpense.who or props.newExpense.forWho
        const styleImageWho = (idUser) => props.newExpense.who.includes
        (idUser) ? "-2px 2px 10px 6px rgba(0,170,255,0.47)" : "none"

        const styleImageForWho = (idUser) => idUser === parseInt(props.newExpense.forWho) ? "-2px 2px 10px 6px rgba(0,170,255,0.47)" : "none"

    //Validation function for the expense



    return (
        <React.Fragment>  
            <motion.div 
                exit = {{y : 1000}}
                animate = {{y : 0}}
                initial = {{y : 1000}} 
                transition={{ type : 'linear' }}
                className = "addExpense">
                <div className = "addExpense__slider" style = {{left : `${positionValue}px`}}>
                    {/*=========================== Qui ===================================*/}
                    <div className = "addExpense__slider__element">
                        <p>Qui ?</p>
                        <div id = "users">
                            {
                                usersDatas.map((user, position) => (
                                    <div id = "users__list" style = {{boxShadow : styleImageWho(user.id)}}onClick = {() => (handleClickWho(user.id))}>
                                        <img key = {user.position} src = {user.image} alt = {user.name} />
                                        <p><span>{
                                            user.surname ? 
                                                user.surname
                                            :
                                                user.name}</span>
                                        </p>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                    {/*=========================== Combien ===================================*/}
                    {props.newExpense.who.length < usersDatas.length ?
                        <div className = "addExpense__slider__element">
                            <p>Combien ?</p>
                            <div>{props.newExpense.howMuch ?
                                `${props.newExpense.howMuch} €`
                                : 
                                `0 €`
                            }
                            </div>
                        </div>
                    : null
                    }

                    <div className = "addExpense__slider__element">
                        <p>Remise ?</p>
                        <div>{props.newExpense.discount ?
                            `${props.newExpense.discount} €`
                            : 
                            `0 €`
                        }
                        </div>
                    </div>
                    
                    {
                        props.newExpense.who.length === 2 ? 
                            <React.Fragment>
                                <div className = "addExpense__slider__element">
                                    <p>Ratio ?</p>
                                    <div>{props.newExpense.ratio ?
                                            <p><span>1/</span>{props.newExpense.ratio}</p>
                                        : 
                                            <p><span>1/</span></p>
                                    }
                                        </div>                         </div>
                                <div className = "addExpense__slider__element">
                                    <p>Pour qui ?</p>
                                    <div id = "users">
                                        {
                                            usersDatas.map(user => (
                                                props.newExpense.who.includes(user.id) ?
                                                <div id = "users__list" style = {{boxShadow : styleImageForWho(user.id)}}onClick = {() => (handleClickForWho(user.id))}>
                                                    <img key = {user.position} src = {user.image} alt = {user.name} />
                                                    <p><span>{
                                                        user.surname ? 
                                                            user.surname
                                                        :
                                                            user.name}</span>
                                                    </p>
                                                </div>

                                                :
                                                    null
                                            ))
                                        }
                                    </div>


                                </div>
                            </React.Fragment>

                        : 
                        null
                    }

                    <div className = "addExpense__slider__element" id = "confirmation">
                        <div> 
                            <button onClick = {() => props.handleClickCancel(setSlideActive, setPositionValue)} ><img src = {cancel} alt = "cancel" /></button>
                            <button onClick = {() => props.handleClickValidate(setSlideActive, setPositionValue)}><img src = {validate} alt = "validate"  /></button>
                        </div>
                    </div>
                </div>
                <div className = "addExpense__buttons">
                    <button onClick = {handleClickPrevious}>{`<`}</button>
                    <button onClick = {handleClickNext}>{`>`}</button>
                </div> 
            </motion.div>  
            
            {
                slideActive === 0 || slideActive === 5 || slideActive === 4 ? 
                        <motion.div 
                        exit = {{y : 1000}}
                        animate = {{y : 0}}
                        initial = {{y : 1000}} 
                        transition={{ type : 'linear' }}
                        className = "keypad__grayscale"></motion.div>
                :
                    props.newExpense.who.length === usersDatas.length && slideActive === 2 ? 
                        <motion.div 
                        exit = {{y : 1000}}
                        animate = {{y : 0}}
                        initial = {{y : 1000}} 
                        transition={{ type : 'linear' }}
                        className = "keypad__grayscale"></motion.div>
                    :
                    props.newExpense.who.length < usersDatas.length && slideActive === 3 || props.newExpense.who.length < 2 && slideActive === 3 ? 
                        props.newExpense.who.length !== 2 ?
                            <motion.div 
                            exit = {{y : 1000}}
                            animate = {{y : 0}}
                            initial = {{y : 1000}} 
                            transition={{ type : 'linear' }}
                            className = "keypad__grayscale"></motion.div>
                                :
                                null
                    : null

            }

            <NumericKeypad
                state = 
                    {
                        props.newExpense.who.length === usersDatas.length ?
                            props.newExpense.discount
                        :
                            slideActive === 1 ?  
                                props.newExpense.howMuch
                            :
                                slideActive === 2 ? 
                                    props.newExpense.discount
                                : 
                                    slideActive === 3 ? 
                                        props.newExpense.ratio
                                    : null
                        
                    }
                setState = {handleChangeInput}
                setBoxClose = {() => props.handleClickValidate(setSlideActive, setPositionValue)}
                boxClose = {props.setAddExpenseBox}
            
            
            />
        </React.Fragment>
    )
}
