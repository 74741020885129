import React, { useState } from "react";
import { numbersDatas, toolsRight, toolsBottom } from "../../datas/keypadDatas";
import { motion } from "framer-motion";

export default function NumericKeypad(props) {
  var [valueStocked, setValueStocked] = useState("")
    
  var handleClickNumberKeypad = (position) => {
    var value = position + 1;
    var total = `${props.state}`
    if (!valueStocked){
      if (total===""|| total==="0"){
        props.setState(value)
      }else{
        props.setState(total + value);
      }
    }else{
      var result = parseFloat(total)* value
      props.setState(`${result}`)
      setValueStocked("")
    }

  };

  var handleClickZero = () => {

    var total = `${props.state}`
    if (!valueStocked){
    if (total===""){
      props.setState(`0.`)
    }else{
      props.setState(total + 0);
    }
   }

  }

  var handleClickComma = () => {
    var total = `${props.state}`
    if (!valueStocked) {
      if(!total.includes(".")){
        if (total===""){
          props.setState("0.")
        }else{
          props.setState(`${total}.`)
        }
      }
    }
  }


  var handleClickReturn = () => {
    var total = `${props.state}`
    if (!valueStocked) {
      if(total !== ""){
        var value = total.slice(0,-1)
        props.setState(value)
      }
    }else{
      props.setState(valueStocked)
      setValueStocked("")
    }
  }

  var handleClickMultiply = () => {
    var total = `${props.state}`
    if(total !== "") {
      if (!total.endsWith("x")){
        setValueStocked(total)
        props.setState(`${total} x`)
      }
    }

  }

  var handleClickValidate = () => {
    if (valueStocked) {
      props.setState(valueStocked)
      setValueStocked("")
    }
    if(props.state === "0.") {
      props.setState("0")
    }
    props.setBoxClose (!props.boxClose)
  }
    return (
    <motion.div 
      exit = {{y : 1000}}
      animate = {{y : 0}}
      initial = {{y : 1000}} 
      transition={{ type : 'linear' }}
      className="keypad">
        <div>
            <div className="keypad__numbers">
            {numbersDatas.map((number, position) => (
                <ButtonKeypad
                key={`${position}-${number.name}`}
                name={number.name}
                image={number.image}
                handleClick={() => {
                    handleClickNumberKeypad(position);
                }}
                />
            ))}
            </div>
            <div className="keypad__toolsRight">
            <ButtonKeypad
              name={toolsRight[0].name}
              image={toolsRight[0].image}
              handleClick = {handleClickReturn}
            />
            <ButtonKeypad
              name={toolsRight[1].name}
              image={toolsRight[1].image}
              handleClick = {handleClickMultiply}
            />
            <ButtonKeypad
              name={toolsRight[2].name}
              image={toolsRight[2].image}
              handleClick = {handleClickValidate}
            />
            </div>
            <div className="keypad__toolsBottom">
              <ButtonKeypad
              name={toolsBottom[0].name}
              image={toolsBottom[0].image}
              handleClick = {handleClickZero}
            />
            <ButtonKeypad
              name={toolsBottom[1].name}
              image={toolsBottom[1].image}
              handleClick = {handleClickComma}
            />

            </div>
        </div>
    </motion.div>
        )}

        function ButtonKeypad(props) {
            return (
              <div className="keypad__numbers__button">
                <img src={props.image} alt={props.name} onClick={props.handleClick} />
              </div>
            );
          }