import React, {useState} from 'react'
import { usersDatas } from "../../datas/usersDatas";
import {userTotal, calculateExpense} from '../../tools/tools'
import close from '../../img/CloseCross.png'
import ExpenseDetail from './ExpenseDetail';
import {motion} from 'framer-motion'
import AddExpense from '../windowInputs/AddExpense';

export  default function Historical(props) {
    const [expenseDetail, setExpenseDetail] = useState({})
    const [updateDetail, setUpdateDetail] = useState(false)
    const [expensePosition, setExpensePosition] = useState(0)

    var handleRemove = (position) => {
        props.setHistorical(props.historical.filter((expense, index) => index !== position))
    }

    var handleClickExpenseDetail = (expense, position) => {
        setExpenseDetail(expense)
        setExpensePosition(position)
    }

    const handleClickValidate = (setSlideActive, setPositionValue) => {
        if(props.newExpense.who.length === usersDatas.length ) {
            if(props.newExpense.discount === "0" || props.newExpense.discount === "" || props.newExpense.discount === "0.") {
                setSlideActive(1)
                return setPositionValue(-322)
            }
            const arrayOfExpense = props.historical
            arrayOfExpense[expensePosition] = props.newExpense
            props.setHistorical(arrayOfExpense)
            props.setNewExpense({
                who : [], 
                howMuch : "",
                discount : "", 
                ratio : "", 
                forWho : 0
        
            })
            setUpdateDetail(false)
            setSlideActive(0)
            setExpenseDetail({})
            return setPositionValue(0)
            
        } else {
            if(props.newExpense.howMuch === "0" || props.newExpense.howMuch === "" || props.newExpense.howMuch === "0.") {
                setSlideActive(1)
                return setPositionValue(-322)
            } else {
                if(props.newExpense.discount === "0" || props.newExpense.discount === "") {
                    var expenseChange = props.newExpense
                    expenseChange['discount'] = "0"
                    props.setNewExpense(expenseChange)
                }
  
                if(props.newExpense.who.length === 2) {
                    if(props.newExpense.ratio === "0" || props.newExpense.ratio === "") {
                        setSlideActive(3)
                        return setPositionValue(-966)
                    }
                    if(props.newExpense.forWho === 0) {
                        setSlideActive(4)
                        return setPositionValue(-1288)
                    }
                } 
            }
  
            const arrayOfExpense = props.historical
            arrayOfExpense[expensePosition] = props.newExpense
            props.setHistorical(arrayOfExpense)

            props.setNewExpense({
                who : [], 
                howMuch : "",
                discount : "", 
                ratio : "", 
                forWho : 0
        
            })
            setUpdateDetail(false)
            setSlideActive(0)
            setExpenseDetail({})
            return setPositionValue(0)
        }
    }

    const handleClickCancel = (setSlideActive, setPositionValue) => {
        setUpdateDetail(false)
        props.setNewExpense({
            who : [], 
            howMuch : "",
            discount : "", 
            ratio : "", 
            forWho : 0
    
        })
        setSlideActive(0)
        setPositionValue(0)
    }




    return(
        <React.Fragment>
        {
            !updateDetail ? 

        <div className="historical">
            <div className="historical__head">
                <div className = "historical__head__name">
                    <img src = {usersDatas.find(user => user.id === props.idUser).image} alt={usersDatas.find(user => user.id === props.idUser).name}/>
                    <p>{
                        usersDatas.find(user => user.id === props.idUser).surname ? 
                            usersDatas.find(user => user.id === props.idUser).surname
                        :
                            usersDatas.find(user => user.id === props.idUser).name
                    
                        }
                    </p>
                </div>
                <h2>Historique</h2>
                <span className="historical__head__total">{`${parseFloat(userTotal(props.idUser, props.total, props.historical)).toFixed(2)} €`}</span>
            </div>
            { Object.entries(expenseDetail).length === 0 ? 
                <div className="historical__expenses">
                <ul>
                {
                    props.historical.filter(expense => expense.who.includes(props.idUser)).length > 0 ? 
                        props.historical.map((expense, position) => (
                            expense.who.includes(props.idUser)  ? 
                                <li key = {position}>
                                    <div className="historical__expenses__close" onClick = {() => handleRemove(position)}>
                                            <img src={close} alt="Fermer" />
                                    </div>
                                    <div className = "historical__expenses__profiles"   onClick = {() => handleClickExpenseDetail(expense, position)}>
                                        {                                    
                                            expense.who.map(userExpense => (
                                                <div>
                                                    <img 
                                                        src = {usersDatas.find(user => user.id === userExpense).image} 
                                                        alt={usersDatas.find(user => user.id === userExpense).name} 
                                                        key={usersDatas.find(user => user.id === userExpense).name}

                                                    />
                                                    <p>{
                                                        usersDatas.find(user => user.id === userExpense).surname ? 
                                                            <span>{usersDatas.find(user => user.id === userExpense).surname}</span>
                                                        :
                                                            <span>{usersDatas.find(user => user.id === userExpense).name}</span>
                                                    
                                                        }
                                                    </p>
                                                </div>

                                            ))
                                        }
                                    </div>
                                    <span>{`${parseFloat(calculateExpense(props.idUser, expense)).toFixed(2)} €`}</span>
                                </li>
                            :

                            null

                        ))
                    : null
                }

            </ul>
            </div>
                :
                <ExpenseDetail 
                    expenseDetail = {expenseDetail}
                    setExpenseDetail = {setExpenseDetail}
                    newExpense = {props.newExpense}
                    setNewExpense = {props.setNewExpense}
                    setUpdateDetail = {setUpdateDetail}
                    expensePosition = {expensePosition}
                    setExpensePosition = {setExpensePosition}
                    historical = {props.historical}
                    setHistorical = {props.setHistorical}
                />
            }
        
        </div>
        :
            <AddExpense 
                newExpense = {props.newExpense}
                setNewExpense = {props.setNewExpense}
                handleClickValidate = {handleClickValidate}
                handleClickCancel = {handleClickCancel}
            />
        }   
        </React.Fragment>
    )

}






























export  function Historical2(props){
    var historicalUserSelected = props.historical.filter(expense => expense.who.includes(props.idUser))
    var [popup, setPopup] = useState(null)

    const handleRemove = (position) => {
        var array = props.historical.filter((expense,index) => index !== position)
        props.setHistorical(array)
    }

    return(
        <React.Fragment>
            <div className="historical">

                <div className="historical__head">
                    <img src = {usersDatas.find(user => user.id === props.idUser).image} alt={usersDatas.find(user => user.id === props.idUser).name}/>
                    <h2>Historique</h2>
                    <span className ="historical__head__total">{`${parseFloat(userTotal(props.idUser, props.total, props.historical)).toFixed(2)} €`}</span>
                </div>

                <div className = "historical__expenses">
                    {
                        historicalUserSelected.length > 0 ? 
                            historicalUserSelected.map((expense, position) => (
                                <li key = {position}>
                                    <div>
                                        {
                                            expense.who.map(userExpense => (
                                                <img src = {usersDatas.find(user => user.id === userExpense).image} alt={usersDatas.find(user => user.id === userExpense).name} key = {usersDatas.find(user => user.id === userExpense).name} />
                                            ))
                                        }
                                    </div>
                                    <span>{`${parseFloat(calculateExpense(props.idUser, expense)).toFixed(2)} €`}</span>
                                    <div className="historical__expenses__close">
                                        <img src={close} alt="Fermer" onClick = {() => handleRemove(position)}/>
                                    </div>
                                </li>
                            ))
    
                        : 

                        null
                    }

                </div>
            </div>
        </React.Fragment>
    )
}

