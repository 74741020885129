
import seb from "../img/seb.png";
import syl from "../img/syl.png";
import tys from "../img/tys.png";
import liza from "../img/liza.png";



export var usersDatas = [
  {
    id: 1,
    image: seb,
    name: "Sébastien",
    surname : "Seb", 
    color: "#4bf9fd",
    couple: true,
  },
  {
    id: 2,
    image: syl,
    name: "Sylvain",
    surname : "Syl", 
    color: "#6df966",
  },
  {
    id: 3,
    image: tys,
    name: "Tys",
    color: "#e64143",
  },
  /*{
    id: 4,
    image: liza,
    name: "Liza",
    color: "#f557ed",
  },*/ 
];
