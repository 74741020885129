import React, { useState } from "react";
import logo from "../img/logo-tickets.png";
import separ from "../img/separateur-P-principale.png";
import plus from "../img/plus.png";
import { usersDatas } from "../datas/usersDatas"; //importe le tableau usersDatas
import UserSummary from "./dashboard/UserSummary";
import Totals from "./dashboard/Totals";
import {AnimatePresence, motion} from "framer-motion"
import ToggleWindow from "./utils/ToggleWindow"
import InputTotal from "./windowInputs/InputTotal"
import NumericKeypad from "./utils/NumericKeypad";
import Historical from "./historical/Historical";
import AddExpense from "./windowInputs/AddExpense";
import LogoSVG from '../svg/Anim-Logo-Tickets.svg'
export default function Main() {
  //Global state of the app
    //1. States for the data of the app 
    var [total, setTotal] = useState("");
    var [historical, setHistorical] = useState([
      /*{
        who : [1,2], 
        howMuch : "12",
        discount : "2", 
        ratio : "3", 
        forWho : 2
      }, 
      {
        who : [1,2], 
        howMuch : "12",
        discount : "2", 
        ratio : "3", 
        forWho : 2
      }, 
      {
        who : [1,2], 
        howMuch : "12",
        discount : "2", 
        ratio : "3", 
        forWho : 2
      }, 
      {
        who
         : [1,2], 
        howMuch : "12",
        discount : "2", 
        ratio : "3", 
        forWho : 2
      }, 
      {
        who : [1,2], 
        howMuch : "12",
        discount : "2", 
        ratio : "3", 
        forWho : 2
      }, 
      {
        who : [1,2], 
        howMuch : "12",
        discount : "2", 
        ratio : "3", 
        forWho : 2
      }, 
      {
        who : [1,2], 
        howMuch : "12",
        discount : "2", 
        ratio : "3", 
        forWho : 2
      }, 
      {
        who : [1,2], 
        howMuch : "12",
        discount : "2", 
        ratio : "3", 
        forWho : 2
      }, 
      {
        who : [1,2], 
        howMuch : "12",
        discount : "2", 
        ratio : "3", 
        forWho : 2
      }, 
      {
        who : [1,2], 
        howMuch : "12",
        discount : "2", 
        ratio : "3", 
        forWho : 2
      }, 
      {
        who : [1,2], 
        howMuch : "12",
        discount : "2", 
        ratio : "3", 
        forWho : 2
      }, 
      {
        who : [1,2], 
        howMuch : "12",
        discount : "2", 
        ratio : "3", 
        forWho : 2
      }, */

    ])
    
    //2. States for the toggle windows
    var [totalBox, setTotalBox] = useState (false) // Boolean
    var [historicalBox, setHistoricalBox] = useState(0) //idUser
    var [addExpenseBox, setAddExpenseBox] = useState(false) //Boolean
    const [newExpense, setNewExpense] = useState({
      who : [], 
      howMuch : "0",
      discount : "0", 
      ratio : "0", 
      forWho : 0
    })
  //Actions functions
    //1. Actions for HistoricalWindow
    const handleClickHistoricalBox = (idUser) => {
      if (historicalBox === 0 || historicalBox === null || !historicalBox){
        setHistoricalBox(idUser)
      }else{
        setHistoricalBox(0)
      }
    }

    //2. Actions for TotalWindow
    const handleClickTotalBox = () => {
      setTotalBox(!totalBox)
    }

    //4. Actions for AddExpenseWindow
    const handleClickAddExpenseBox = () => {
      setAddExpenseBox(!addExpenseBox)
    }
    const handleClickValidate = (setSlideActive, setPositionValue) => {
      if(newExpense.who.length === usersDatas.length ) {
          if(newExpense.discount === "0" || newExpense.discount === "" || newExpense.discount === "0.") {
              setSlideActive(1)
              return setPositionValue(-322)
          }
          setHistorical(state => {
              return [newExpense, ...state]
          })
          setNewExpense({
              who : [], 
              howMuch : "",
              discount : "", 
              ratio : "", 
              forWho : 0
      
          })
          setSlideActive(0)
          return setPositionValue(0)
          
      } else {
          if(newExpense.howMuch === "0" || newExpense.howMuch === "" || newExpense.howMuch === "0.") {
              setSlideActive(1)
              return setPositionValue(-322)
          } else {
              if(newExpense.discount === "0" || newExpense.discount === "") {
                  var expenseChange = newExpense
                  expenseChange['discount'] = "0"
                  setNewExpense(expenseChange)
              }

              if(newExpense.who.length === 2) {
                  if(newExpense.ratio === "0" || newExpense.ratio === "") {
                      setSlideActive(3)
                      return setPositionValue(-966)
                  }
                  if(newExpense.forWho === 0) {
                      setSlideActive(4)
                      return setPositionValue(-1288)
                  }
              } 
          }

           setHistorical(state => {
              return [newExpense, ...state]
          })

          setNewExpense({
              who : [], 
              howMuch : "",
              discount : "", 
              ratio : "", 
              forWho : 0
      
          })
          setSlideActive(0)
          return setPositionValue(0)
      }
  }

  const handleClickCancel = (setSlideActive, setPositionValue) => {
    setNewExpense({
        who : [], 
        howMuch : "",
        discount : "", 
        ratio : "", 
        forWho : 0

    })
    setSlideActive(0)
    setPositionValue(0)
  }



  return (
    <AnimatePresence>
      <motion.div 
        exit = {{opacity : 0}}
        animate = {{opacity : 1}}
        initial = {{opacity : 0}}
        className="dashboard"
      >
        {/*==============BEGIN WINDOWS=================*/}

          {/*==========================================
                        Window Total 
          ==========================================*/}
          {
          totalBox ?
            <ToggleWindow
              handleClickWindow = {handleClickTotalBox}
            >
              <InputTotal
                total = {total}
              />
              <NumericKeypad
                state = {total}
                setState = {setTotal}
                setBoxClose = {setTotalBox}
                boxClose = {totalBox}
              />
            </ToggleWindow>
          : 
            null
          }

          {/*==========================================
                        Window AddExpense
          ==========================================*/}
          {
          addExpenseBox ?
            <ToggleWindow
              handleClickWindow = {handleClickAddExpenseBox}
            >
              <AddExpense 
                addExpenseBox = {addExpenseBox}
                setAddExpenseBox = {setAddExpenseBox}
                newExpense = {newExpense}
                setNewExpense = {setNewExpense}
                handleClickValidate = {handleClickValidate}
                handleClickCancel = {handleClickCancel}
              />
            </ToggleWindow>
          : 
            null
          }


          {/*==========================================
                        Window Historical 
          ==========================================*/}
          {
          historicalBox ?
            <ToggleWindow
                handleClickWindow = {handleClickHistoricalBox}
            >
            <Historical
              total = {total}
              historical = {historical}
              idUser ={historicalBox}
              setHistorical = {setHistorical}
              newExpense = {newExpense}
              setNewExpense = {setNewExpense}
            />
            </ToggleWindow>
          : 
            null
          }
        
        {/*==============END WINDOWS COMPONENTS=================*/}


        {/*==============BEGIN WINDOWS=================*/}
        <div className="dashboard__header">
          <object type="image/svg+xml" data={LogoSVG}>svg-animation</object>
          </div>
            <UserSummary 
                total={total}
                historical={historical}
                handleClickHistoricalBox = {handleClickHistoricalBox}
            />
            {/* ------------- Begin of separator ----------------*/}
            <img
                className="dashboard__separ-princ"
                src={separ}
                alt="séparateur"
            />
            {/* ------------- End of separator ----------------*/}
          <Totals 
                handleClickTotalBox = {handleClickTotalBox}
                total={total}
                historical={historical}
          />
          <div className="dashboard__plus" onClick = {handleClickAddExpenseBox}>
            <img src={plus} alt="ajouter"/>
          </div>
      </motion.div> 
    </AnimatePresence>
  )


}

