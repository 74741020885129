import { usersDatas } from "../../datas/usersDatas";
import { userTotal } from "../../tools/tools";


export default function UserSummary (props) {
    /*Props available : 
        - props.total : Accessor of state total
        - props.historical : Accessor of historical
        - props.handleClickHistoricalBox : Change state of historicalBox
    */
   const overflowUsers = usersDatas.length > 3 ? "scroll" : "hidden"
    return(
        <div>
            <div className="dashboard__bodyUsers" style = {{overflowY : overflowUsers}}>
                {usersDatas.map((user) => (
                    <div className="dashboard__bodyUsers__user" key={user.id} onClick = {() => {props.handleClickHistoricalBox(user.id)}}>
                        <div className = "dashboard__bodyUsers__user--profile">
                            <img src={user.image} alt={user.name} />
                            <p>{
                                user.surname ? 
                                    user.surname
                                :
                                    user.name}</p>
                        </div>
                        

                        <span className="dashboard__bodyUsers__user__total">
                            {user.couple ? 
                                <span>
                                    {`${parseFloat((userTotal(user.id, props.total, props.historical) + userTotal(3, props.total, props.historical ))).toFixed(2)} € / `}
                                </span>
                            : 
                                null}
                            <span>
                                <span style={{ color: user.color }}>{parseFloat(userTotal(user.id, props.total, props.historical)).toFixed(2)}</span> €
                            </span>
                        </span>
                     </div>
                ))}
            </div>
        </div>
    )
}